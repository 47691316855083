

























































































import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import { useTenantCreationForm } from '@/composables/store/Management/useTenantCreationForm';
import { useFormValidation } from '@/composables/function/Management/useFormValidation';
import { onMounted, ref } from '@vue/composition-api';
import { useRouter } from '@/composables/function/Common/useRouter';

export default defineComponent({
    name: 'TenantForm',
    setup() {
        onMounted(() => {
            initTenant();
            fetchStatsServer();
        });
        const router = useRouter();
        const { error, statsServerList, tenantForm, fetchStatsServer, postCreation, initTenant, initCompany } =
            useTenantCreationForm();
        const { tenantCreationRules } = useFormValidation();
        const valid = ref(false);

        const create = async (): Promise<void> => {
            const result = await postCreation();
            if (result) {
                await router.push({
                    name: 'CompanyDetail',
                    params: {
                        id: `${tenantForm.value.company.id}`,
                        name: `${tenantForm.value.company.name}`,
                    },
                });
                initTenant();
                initCompany();
            }
        };

        return {
            error,
            statsServerList,
            tenantForm,
            fetchStatsServer,
            postCreation,
            tenantCreationRules,
            valid,
            create,
        };
    },
});
